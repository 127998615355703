/* Default styles */
body {
  background-image: url("https://res.cloudinary.com/duj2m3mpi/image/upload/v1706348060/prefabxpressb/WhatsApp_Image_2024-01-14_at_11.12.08_PM_vgdyfz.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-size: 20px;
  font-family: Catamaran;
  margin-top: 4%;
  background-size: cover;
}

nav {
  /* position: fixed; */
  width: 100%;
  top: 0;
  background-color: #fff;
  /* Change this to the background color you want for your navbar */
  z-index: 1000;
  /* Adjust the z-index as needed */
}

.container-flex {
  background: white;
}

#home_aboutus {
  text-align: center;
  height: auto;
  padding-top: 30px;
}

#home_read_more {
  text-align: center;
}

#home_product_list {
  height: auto;
}

#home_page_product_block {
  height: auto;
}

/* #home_view_all_products {
  border: 2px solid red;
} */

#hover_effect {
  padding: 50px;
}

#product_information1,
#product_information2 {
  height: auto;
  margin-bottom: 4%;
}

.cards-inline>* {
  display: inline-block;
  margin-right: 10px;
}

.col {
  height: 100px;
  padding-top: 30px;
}

.zoom {
  padding: 50px;
  height: auto;
  width: 300px;
  margin-top: auto;
  transition: transform 0.2s;
}

.zoom:hover {
  transform: scale(1.1);
}

/* Media Queries */
@media screen and (max-width: 767px) {

  /* Adjust styles for screens smaller than 768px width */
  body {
    font-size: 16px;
  }

  #home_aboutus {
    height: auto;
  }

  #home_product_list {
    height: inherit;
    overflow: auto;
  }

  .cards-inline>* {
    margin-right: 5px;
  }

  .col {
    height: auto;
  }

  .zoom {
    margin-right: 10px;
    width: 100%;
  }

  #card {
    width: 10rem;
  }
}

/* Add this to your CSS file or style tag */
@media (max-width: 767px) {

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  /* Add more styles as needed */
}

@media (max-width: 930px) {
  #card {
    width: 10rem;
  }
  /* Add more styles as needed */
}