#product_view_container {
  height: 500px;
}

#product_view_details {
    text-align: left;
    height: auto;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 767px) {
    #product_view_details {
      margin-top:4%; 
    }
    
  }
  